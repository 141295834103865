import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout";

const Joan = () => {
  return (
    <Layout>
      <Head title="Joan Mitchell" />
      <h3>Joan Mitchell</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Chicago, New York, Paris
      </h4>
      <p>1925 - 1992</p>
      <p>
        <OutboundLink href="https://en.wikipedia.org/wiki/Joan_Mitchell">
          Wikipedia
        </OutboundLink>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <img
          src="https://sfmoma-media-dev.s3.us-west-1.amazonaws.com/www-media/2021/06/08102334/Mitchell_Untitled_1992-copy-768x603.jpg"
          alt="Joan Mitchell Art"
        />
        <img
          src="https://sfmoma-media-dev.s3.us-west-1.amazonaws.com/www-media/2021/06/17110650/FC.838_01_H02-Large-JPEG_5x7-768x437.jpg"
          alt="Joan Mitchell Art"
        />
        <img
          src="https://imageio.forbes.com/specials-images/imageserve/617ea80ba9276872511e3a1e/Joan-Mitchell--Sans-neige--1969--Carnegie-Museum-of-Art--Pittsburgh--gift-of-the/960x0.jpg?format=jpg&width=960"
          alt="Joan Mitchell Art"
        />
        <img
          src="https://sfmoma-media-dev.s3.us-west-1.amazonaws.com/www-media/2021/06/08101616/Mitchell_My_Landscape_II_1967-768x1170.jpg"
          alt="Joan Mitchell Art"
        />
        <img
          src="https://sfmoma-media-dev.s3.us-west-1.amazonaws.com/www-media/2021/06/17111401/x2021.4057.054_01_G02-Large-JPEG_5x7-768x1027.jpg"
          alt="Joan Mitchell Art"
        />
        <img
          src="https://sfmoma-media-dev.s3.us-west-1.amazonaws.com/www-media/2021/06/03101214/Sans-Neige-Color-Corrected-768x402.jpg"
          alt="Joan Mitchell Art"
        />
        <img
          src="https://upload.wikimedia.org/wikipedia/en/7/76/Joan_Mitchell_in_her_V%C3%A9theuil_studio%2C_1983.jpg"
          alt="Joan Mitchell Art"
        />
        <img
          src="https://www.artnews.com/wp-content/uploads/2021/08/19_Mitchell_Weeds_1976.jpg?w=1200"
          alt="Joan Mitchell Art"
        />
      </div>
    </Layout>
  );
};

export default Joan;
